@import 'variables';

.full-h-w() {
  height: 100%;
  width: 100%;
}

.full-w {
  width: 100%;
}

.full-h {
  height: 100%;
}

.slider-horisontal-fix() {
  @horisontal-fix: 4px;

  position: relative;
  left: @horisontal-fix;
  width: calc(~'100% -' @horisontal-fix * 2);
}

.slider.horisontal-fix {
  .slider-horisontal-fix;
}

.progress.align-fix {
  &.left-align {
    position: relative;
    @left-fix: @progress-line-padding-horizontal;

    left: -@left-fix;
    width: calc(~'100% +' @left-fix);
  }
}

.relative {
  position: relative;
}

.nowrap-text {
  white-space: nowrap;
}

.wrap-text {
  white-space: normal;
}

.box-shadow-none {
  box-shadow: none;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-flow-row {
  flex-flow: row;
}

.flex-shrink-col-static {
  flex-grow: 0;
  flex-shrink: 0;
}

.flex-shrink-col-dynamic {
  flex-grow: 1;
  flex-shrink: 1;
}

.flex-align-content-center {
  align-content: center;
}

.flex-align-items-stretch {
  align-items: stretch;
}

.align-baseline {
  align-items: baseline;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

.align-middle {
  display: flex;
  align-items: center;
}

.align-middle-horizontally {
  display: flex;
  justify-content: center;
}

.float-right {
  float: right;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hyphens {
  hyphens: auto;
}

.overflow-visible {
  overflow: visible;
}

.no-wrap-no-overflow() {
  white-space: nowrap;
  overflow-x: hidden;
}

.no-wrap-no-overflow {
  .no-wrap-no-overflow;
}

.paragraph-readability-max-width {
  max-width: 500px; // Recommended width for all paragraphs with text so that char count !> 70 on one row
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-color-primary {
  color: @primary-dark;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.size-deep * {
  // For overriding rsuite child elements out of reach with .hx classes
  font-size: inherit !important;
}

.avatar-dimension(@size, @avatar-dimension) {
  // .avatar-dimension(lg, @avatar-dimension);
  :global {
    .rs-avatar-@{size} {
      // to increase the height of logo
      width: @avatar-dimension;
      height: @avatar-dimension;
      line-height: @avatar-dimension;

      .rs-avatar-image {
        // to increase the height of logo
        width: @avatar-dimension;
        height: @avatar-dimension;
        line-height: @avatar-dimension;
      }
    }
  }
}

.loader-design() {
  // In order to customize the loader, we need to apply it's animation.
  // Taken from rsuite/lib/styles/keyframes.less, line 387
  @keyframes loaderSpin {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }

  // Hacky way to customize the rsuite loader
  :global(.rs-loader-spin) {
    &::after {
      border-width: 5px;
      border-style: solid;
      border-color: @primary-dark @B050 @B050;
      animation: loaderSpin 0.5s infinite linear;
    }
  }
}

// To truncate text with ellipsis after a certain number of lines
.multiline-ellipsis(@lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: @lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Icon sizes

.icon-size-double {
  font-size: 2em;
}

.caret-down-icon-background(@color: @headings-color, @dim: 1em) {
  @escaped-color: escape(@color); // url(data) will break if not escaping #'s

  // Note that the currentColor is not actually followed so we need to interpolate the color itself into it rather */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='@{dim}' height='@{dim}' viewBox='0 0 21 32' fill='@{escaped-color}' aria-hidden='true' focusable='false' class='rs-icon' aria-label='angle down' data-category='legacy' %3E%3Cpath d='M19.196 13.143a.612.612 0 01-.179.411l-8.321 8.321c-.107.107-.268.179-.411.179s-.304-.071-.411-.179l-8.321-8.321c-.107-.107-.179-.268-.179-.411s.071-.304.179-.411l.893-.893a.582.582 0 01.411-.179c.143 0 .304.071.411.179l7.018 7.018 7.018-7.018c.107-.107.268-.179.411-.179s.304.071.411.179l.893.893a.617.617 0 01.179.411z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.tag-primary {
  background-color: @primary;
  color: @dim-mid;
}

.no-flex-wrap {
  flex-flow: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.check-icon-vertical-alignment {
  margin-top: 3px;
  flex-shrink: 0; // Don't shrink with container - for responsiveness
  vertical-align: unset; // Override default .rs-icon vertical alignment, can cause additional offset
}

.cursor-pointer {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--rs-text-disabled);
}

.hide-element {
  display: none;
}

.modal-backdrop-style {
  mix-blend-mode: multiply;
  background: @modal-backdrop-bg;
  backdrop-filter:
    blur(
      @modal-backdrop-blur
    ); // Magic number for blur to match design
}

.font-semibold {
  font-weight: 600;
}

.highlight-text {
  color: @primary-dark;

  &-primary {
    color: @primary;
  }
}

.button-fixed-bottom {
  font-size: @font-size-large;
  border-radius: @border-radius;
  position: fixed;
  bottom: @spacer * 2;
  left: @spacer * 2;
  right: @spacer * 2;
  width: auto;
  padding-top: @spacer * 2;
  padding-bottom: @spacer * 2;
  z-index: @z-index-color-palette + 2; // To be above product card gradient z-index

  &:global(.rs-btn-disabled) {
    background-color: @grey;
    opacity: 1;
  }
}
